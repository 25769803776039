import actions from './actions';
import {
  checkUserRegistered,
  loginWithPassword,
  loginWithGoogle,
  getNotifications,
  getHubNotifications,
  getUserUploadSize,
  registerUser,
  confirmUser,
  forgetPassword,
  getUserProfile,
} from './api';
import toasterActions from 'components/toaster/container/actions';
import axios from 'axios';


const loginPerformances = (dispatch) => {
  const setLoginState = (value) => {
    dispatch(actions.setLoginState(value));
  };
  const setSelectedHUbModeId = (value) => {
    dispatch(actions.setSelectedHUbModeId(value));
  };
  const setSelectedHUbModeType = (value) => {
    dispatch(actions.setSelectedHUbModeType(value));
  };
  const setRolesContext = (value) => {
    dispatch(actions.setRoles(value));
  };
  const setSelectedRoleContext = (value) => {
    dispatch(actions.SetSelectedRoles(value));
  };
  const setRedirectContext = (value) => {
    dispatch(actions.setRedirect(value));
  };
  const setFamilySize=(value)=>{
    dispatch(actions.setFamilySize(value));
  }
  const setFamilyCurrency = (value) => {
    dispatch(actions.setFamilyCurrency(value));
  };
  const setFamilyType = (value) => {
    dispatch(actions.setFamilyType(value));
  };
  const checkUserRegisteredContext = async (cellphone) => {
    try {
      const result = await checkUserRegistered(cellphone);
      if (result) {
        dispatch(actions.setLoginState(2));
      } else {
        dispatch(actions.setLoginState(3));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const loginWithPasswordContext = async (data) => {
    try {
      const result = await loginWithPassword(data);
      if (result) {
        localStorage.setItem('token', result.refresh_token);
        dispatch(actions.setProfile(result));
        dispatch(actions.setToken(result.refresh_token));
        // axios.defaults.headers.common.Authorization = 'Bearer'+' '+result.refresh_token
        dispatch(
          actions.SetSelectedRoles(
            !result.defualt_family || result.defualt_family === 'self' ? '' : result.defualt_family
          )
        );
        axios.defaults.headers.common.family =
          !result.defualt_family || result.defualt_family === 'self'
            ? result.cellphone
            : result.defualt_family;
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong password' }));
      console.error(e);
    }
  };
  const loginWithGoogleContext = async (data) => {
    try {
      const result = await loginWithGoogle(data);
      if (result) {
        localStorage.setItem('token', result.refresh_token);
        dispatch(actions.setProfile(result));
        dispatch(actions.setToken(result.refresh_token));
        // // axios.defaults.headers.common.Authorization = 'Bearer'+' '+result.refresh_token
        dispatch(
          actions.SetSelectedRoles(
            !result.defualt_family || result.defualt_family === 'self' ? '' : result.defualt_family
          )
        );
        axios.defaults.headers.common.family =
          !result.defualt_family || result.defualt_family === 'self'
            ? result.cellphone
            : result.defualt_family;
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong password' }));
      console.error(e);
    }
  };
  const setTokenContext = async (value) => {
    dispatch(actions.setToken(value));
  };
  const setSocketContext = async (value) => {
    dispatch(actions.setSocket(value));
  };
  const setNotificationsContext = async (value) => {
    dispatch(actions.setNotifications(value));
  };
  const setConversationsNotificationsContext = async (value) => {
    dispatch(actions.setConversationsNotifications(value));
  };
  const newNotificationReceived = async (value) => {
    dispatch(actions.newNotificationReceived(value));
  };
  const getNotificationsContext = async () => {
    try {
      const result = await getNotifications();
      if (result) {
        dispatch(actions.setNotifications(result.notifications));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getHubNotificationsContext = async (hubId) => {
    try {
      const result = await getHubNotifications(hubId);
      if (result) {
        dispatch(actions.setNotifications(result.notifications));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const registerContext = async (data) => {
    try {
      const result = await registerUser(data);
      if (result) {
        dispatch(actions.setEmail(data.cellphone));
        dispatch(actions.setRedirect('/otp'));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const confirmUserContext = async (data) => {
    try {
      const result = await confirmUser(data);
      if (result) {
        localStorage.setItem('token', result.access_token);
        dispatch(actions.setProfile(result));
        dispatch(actions.setToken(result.access_token));
        // axios.defaults.headers.common.Authorization = 'Bearer'+' '+result.access_token
        // axios.defaults.headers.common.family = data.cellphone
        dispatch(
          actions.SetSelectedRoles(
            !result.defualt_family || result.defualt_family === 'self' ? '' : result.defualt_family
          )
        );
        axios.defaults.headers.common.family =
          !result.defualt_family || result.defualt_family === 'self'
            ? result.cellphone
            : result.defualt_family;
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong password' }));
      console.error(e);
    }
  };

  const forgetPasswordContext = async (data) => {
    try {
      const result = await forgetPassword(data);
      if (result) {
        dispatch(actions.setEmail(data));
        dispatch(actions.setRedirect('/otp'));
        dispatch(toasterActions.updateToaster({ type: 'info', message: 'verification code send' }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getUserProfileContext = async () => {
    try {
      const result = await getUserProfile();
      if (result) {
        dispatch(actions.setProfile(result));
        // axios.defaults.headers.common.family = result.cellphone
        dispatch(
          actions.SetSelectedRoles(
            !result.defualt_family || result.defualt_family === 'self' ? '' : result.defualt_family
          )
        );
        axios.defaults.headers.common.family =
          !result.defualt_family || result.defualt_family === 'self'
            ? result.cellphone
            : result.defualt_family;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setProfile = (value) => {
    dispatch(actions.setProfile(value));
  };

  const getUserUploadSizeContext = async () => {
    try {
      const result = await getUserUploadSize();
      if (result ) {
        dispatch(actions.setFamilySize(result[0].familySize.size));
        dispatch(actions.setUsedSize(result[0].totalSize));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    setLoginState,
    setSelectedHUbModeId,
    setSelectedHUbModeType,
    setRolesContext,
    setSelectedRoleContext,
    setRedirectContext,
    setFamilyCurrency,
    setFamilyType,
    checkUserRegisteredContext,
    loginWithPasswordContext,
    loginWithGoogleContext,
    setTokenContext,
    setSocketContext,
    setNotificationsContext,
    setConversationsNotificationsContext,
    getNotificationsContext,
    newNotificationReceived,
    getHubNotificationsContext,
    registerContext,
    confirmUserContext,
    forgetPasswordContext,
    getUserProfileContext,
    setProfile,
    getUserUploadSizeContext,
    setFamilySize
  };
};

export default loginPerformances;
