import React, { Profiler, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import MainContext from 'MainContext';
// import ProfileModal  from './profileModal';
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Storage = styled.div`
  position: absolute;
  bottom: 2.6vw;
  left: 50%;
  transform: translateX(-50%);
  width: 10.255vw;
  height: 5.465vw;
  border-radius: 0.937vw;
  background-color: ${(props) => props.theme.cardOnCardBackground};
`;

const Title = styled.div`
  position: absolute;
  top: 0.78vw;
  left: 0.78vw;
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.728vw;
  font-weight: 500;
`;

const Bar = styled.div`
  position: absolute;
  bottom: 1.04vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.textPrimary};
  width: 8.693vw;
  height: 0.832vw;
  border-radius: 0.832vw;
  overflow: hidden;
`;

const UsedBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.blueColorPrimary};
  width: ${(props) => props.width + '%'};
  height: 0.832vw;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 10.619vw;
  left: 50%;
  transform: translateX(-50%);
`;

const UploadBarWrapper = styled.div`
  color: ${(props) => props.theme.textGrey};
  font-size: 0.631vw;
  font-weight: 400;
  position:absolute;
  top:2.5vw;
  right:0.9vw; 
`;

const Tab = (props) => {
  const { theme } = useContext(ThemeContext);
  const { profile } = props;
  const { getUserUploadSizeContext, usedSize, familySize

  } = useContext(MainContext).login;
  const [uploadSize, setUploadSize] = useState(0);
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  // const [profileInfoModal, setProfileInfoModal] = useState(false);
  let history = useHistory();

  const onUpgradeClick = () => { };
  
  useEffect(() => {
    if (usedSize) {
      
      setUploadSize(usedSize)

      if (uploadSize >= 1000000000) {
        setTotalUploadSize(uploadSize / 1000000000);
      }
      if (uploadSize < 1000000000) {
        setTotalUploadSize(uploadSize / 1000000);
      }
    }
  }, [profile, getUserUploadSizeContext])

  const onStorageClick = () => { 
    // setProfileInfoModal(!profileInfoModal)
    // /plans
    history.push("/plans");
    // console.log("2222", profile, profileInfoModal)
  }

  return (
    <Wrapper theme={theme}>
      <ButtonWrapper>
        <Link style={{ textDecoration: 'none' }} to="/plans">
          <Button onClick={onUpgradeClick} marginTop={0} text="UPGRADE" size="medium" />
        </Link>
      </ButtonWrapper>
      <Storage theme={theme} onClick={onStorageClick}>
        <Title theme={theme}>Storage</Title>
        <UploadBarWrapper theme={theme}>
          {`${Math.round(totalUploadSize * 10) / 10} ${uploadSize >= 1000000000 ? "GB" : "MB"}`} of {(familySize / 1000000000)} GB
        </UploadBarWrapper>
        <Bar theme={theme}>
          <UsedBar width={(uploadSize * 100) / familySize} theme={theme} />
        </Bar>
      </Storage>
      {/* <ProfileModal
        open={profileInfoModal}
        toggle={setProfileInfoModal}
        profile = {profile}
        uploadSize ={uploadSize}
        setUploadSize = {setUploadSize}
        totalUploadSize={totalUploadSize}
        setTotalUploadSize ={setTotalUploadSize}
        familySize = {familySize}
      /> */}
    </Wrapper>
  );
};

export default Tab;
